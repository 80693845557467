'use strict';

angular
    .module('informaApp')
    .component(
        'systemNotifications',
        {
            templateUrl: 'components/admin-sections/system-notifications/template.ptl.html',
            controller(SystemNotifications) {
                const vm = this;

                const initialFormState = {
                    title: null,
                    content: null,
                    expirationDate: moment()
                };

                vm.form = {...initialFormState};

                // Needed to determine if notification record already exists or not
                // to use create or update method accordingly
                vm.isBackendNotificationLoading = true;
                vm.backendNotification = null;

                SystemNotifications.getExistingSystemNotification().then((notificationData) => {
                    vm.isBackendNotificationLoading = false;
                    vm.backendNotification = notificationData;

                    if (vm.backendNotification) {
                        const { title, content, expirationDate } = vm.backendNotification;
                        vm.form.title = title;
                        vm.form.content = content;
                        vm.form.expirationDate = moment(new Date(
                            SystemNotifications.convertToLondonTime(expirationDate)
                        ));
                    }
                });

                const handleAsyncNotificationAction = (actionPromise) => {
                    vm.isBackendNotificationLoading = true;

                    actionPromise.finally(() => vm.isBackendNotificationLoading = false);
                };

                vm.onNotificationSubmit = () => handleAsyncNotificationAction(
                    SystemNotifications.submitNotification(
                        vm.backendNotification,
                        vm.form
                    ).then((savedNotification) => {
                        if (savedNotification) {
                            vm.backendNotification = savedNotification;
                        }
                    })
                );

                vm.deleteSystemNotification = ($event) => {
                    $event.preventDefault();

                    handleAsyncNotificationAction(
                        SystemNotifications
                            .deleteNotification(vm.backendNotification)
                            .then(() => {
                                vm.form = {...initialFormState};
                                vm.backendNotification = null;
                                vm.isBackendNotificationLoading = false;
                            })
                    );
                };
            }
        }
    );